import { Feature } from "geojson";
import { MapInstanceState } from "../store/map/map.reducer";
import { MapDataLayer } from "./maps/maps-data-layer";
import { MapFeatureFilter } from "./maps.service";
import { store } from "@stencil/redux";
import { getAppFormsWithGeometry } from "../store/selectors";
import { CoreoForm } from "../types";
import AppDatabase from "./db/app-db.service";
import { formFeatureStyler } from "./maps/maps.utils";

const arrayEquals = <T = any>(a: Array<T>, b: Array<T>): boolean => {
  return Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((val, index) => val === b[index]);
}


export class MapFeaturesService {

  public static instance: MapFeaturesService = new MapFeaturesService();

  private constructor() { }

  private featuresLayer: MapDataLayer = new MapDataLayer(false); // Not Clustered
  private clustersLayer: MapDataLayer = new MapDataLayer(true); // Clustered
  private features: Feature[] = [];

  public async addTo(map: mapboxgl.Map) {
    await this.featuresLayer.addTo(map);
    await this.clustersLayer.addTo(map);
  }

  public async loadFeatures(projectId: number, state: MapInstanceState, featureFilter?: MapFeatureFilter) {
    const forms = getAppFormsWithGeometry(store.getState());
    const features: Promise<Feature[]>[] = [];
    for (const form of forms) {
      features.push(this.loadFormFeatures(projectId, form));
    }
    this.features = (await Promise.all(features)).flat();

    if (state) {
      MapFeaturesService.instance.filterMapFeatures(state, featureFilter);
    }
  }

  private async loadFormFeatures(projectId: number, form: CoreoForm) {
    const features = await AppDatabase.instance.records.findProjectMapFeatures(query =>
      query.where('projectId = ?', projectId)
        .where('surveyId = ?', form.id)
        .field('createdAt')
        .field('state')
        .field('userId')
        .field('data.*')
        .join(`records_${form.id}`, 'data', `records.id = data.id`)
    );

    const styler = await formFeatureStyler(projectId, form);
    return styler(features);
  }

  async applyMapDataLayers(state: MapInstanceState, previousState: MapInstanceState) {

    const geometricForms = getAppFormsWithGeometry(store.getState());

    this.featuresLayer.clear();
    this.clustersLayer.clear();

    if (geometricForms.length > 0) {
      for (const form of geometricForms) {
        this.featuresLayer.addForm(form);
        this.clustersLayer.addForm(form);
      }

      for (let i = 0; i < state.dataLayers.length; i++) {
        this.featuresLayer.setSort(state.dataLayers[i].sourceId, i + 1);
        this.clustersLayer.setSort(state.dataLayers[i].sourceId, i + 1);
      }

      this.featuresLayer.update();
      this.clustersLayer?.update();

      // If we have change data layers, re-filter the data
      if (previousState) {
        const current = state.dataLayers.filter(a => a.enabled).map(a => a.sourceId).sort();
        const previous = previousState?.dataLayers.filter(a => a.enabled).map(a => a.sourceId).sort();

        if (!arrayEquals(current, previous)) {
          this.filterMapFeatures(state);
        }
      }
    } else {
      this.clear();
    }
    this.clustersLayer.move();
    this.featuresLayer.move();
  }

  public filterMapFeatures(state: MapInstanceState, customFilter?: MapFeatureFilter) {
    const allDisabled = state.dataLayers.every(a => !a.enabled);
    const formIds = new Set([...state.dataLayers.filter(a => a.enabled).map(d => d.sourceId)]);

    // If there are no forms, and no custom filter function, return now
    if ((allDisabled || (formIds.size === 0)) && typeof customFilter === 'undefined') {
      return this.clear();
    }

    let filtered = this.features.filter(f => formIds.has(f.properties.surveyId));
    if (customFilter) {
      filtered = filtered.filter(customFilter());
    }

    this.featuresLayer.setFeatures(filtered);
    this.clustersLayer.setFeatures(filtered.map(({ properties, ...f }) => ({
      ...f,
      geometry: properties.center,
      properties: {
        ...properties,
        __originalGeometryType: f.geometry.type
      }
    })));
  }

  updateClustering(cluster: boolean) {
    if (cluster) {
      this.featuresLayer.setZoomRange(12, 24);
      this.clustersLayer.setZoomRange(0, 12);
      this.clustersLayer.show();
    } else {
      this.featuresLayer.setZoomRange(0, 24);
      this.clustersLayer.hide();
    }
  }

  public getFeatures() {
    return this.features;
  }

  public clear() {
    this.featuresLayer.clear();
    this.clustersLayer?.clear();
    this.featuresLayer.setFeatures([]);
    this.clustersLayer?.setFeatures([]);
  }
}
